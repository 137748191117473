<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
      .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingCollaboratorGoalsList || isLoadingCollaboratorGoalsListArchived ||  !headerLoaded')
          .loading-bg-inner
              .loader
                .outer
                .middle
                .inner
          .mt-5
              br
              br
              br
              | Chargement de la liste des objectifs par collaborateur...
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :editSettings='editSettings'  :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked"  @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :archive="archive" :archiveMode="archiveMode" @deleteButtonClicked="deleteButtonClicked" @archiveButtonClicked="archiveButtonClicked" @restoreButtonClicked="restoreButtonClicked")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";
import { statisticsPeriods } from "@/types/api-orisis/enums/enums";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "collaboratorGoal",
      uid: "collaboratorGoals",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher des objectifs",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouveaux objectifs",
        titleEdit: "Modifier les objectifs",
        titleField: "label",
      },
      allowGrouping: false,
    };
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "collaboratorGoalsList",
      "isLoadingCollaboratorGoalsList",
      "collaboratorGoalsListArchived",
      "isLoadingCollaboratorGoalsListArchived",
      "workspaceSelected",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters[this.name + "sListArchived"];
      } else {
        return this.$store.getters[this.name + "sList"];
      }
    },
  },
  async created() {
    if (!this.collaboratorsList || this.collaboratorsList.length == 0) {
      await this.getCollaborators({});
    }
    this.setHeaderData();

    this.getCollaboratorGoals({}).then(async (res) => {
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getCollaborators",
      "getCollaboratorGoals",
      "getCollaboratorGoalsArchived",
      "createCollaboratorGoal",
      "updateCollaboratorGoal",
      "archiveCollaboratorGoals",
      "deleteCollaboratorGoals",
      "restoreCollaboratorGoals",
    ]),
    formatCurrency,
    setColumnsByObject,
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "number",
          field: "collaboratorId",
          filterTemplate: "collaboratorFilterTemplate",
          headerText: "Collaborateur",
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          dataSource: this.collaboratorsList,
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
            name: "collaboratorId",
          },
          // editType: "dropdownedit",
        },
        {
          type: "string",
          field: "statisticsPeriod",
          filterTemplate: "statisticsPeriodsFilterTemplate",
          headerText: "Périodicité des objectifs",
          width: 160,
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          template: "statisticsPeriodTemplate",
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          defaultValue: 1,
          edit: {
            source: statisticsPeriods,
            fields: { text: "label", value: "value" },
            type: "dropdown", //<<FIX
            name: "statisticsPeriod",
          },
        },

        {
          type: "number",
          format: "formatNumber",
          field: "numbersActivitiesByPeriod",
          headerText: "Nb contacts utiles",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 0 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "numbersQuotesSentByPeriod",
          headerText: "Nb devis envoyés",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 0 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountQuotesSentByPeriod",
          headerText: "Mt Devis envoyés",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          editType: "numericedit",
          defaultValue: 0,
          edit: {
            params: {
              decimals: 0,
              min: 0,
              format: "###### €",
              showSpinButton: false,
              step: 1,
            },
          },
          validationRules: { required: true },
          format: "formatCurrency",
          allowFiltering: true,
        },
        {
          type: "number",
          format: "formatNumber",
          field: "numbersQuotesSignedByPeriod",
          headerText: "Nb devis signés",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 0 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountQuotesSignedByPeriod",
          headerText: "Mt Devis signés",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowFiltering: true,
          visible: true,
          showFooterSum: true,
          editType: "numericedit",
          defaultValue: 0,
          edit: {
            params: {
              decimals: 0,
              min: 0,
              format: "###### €",
              showSpinButton: false,
              step: 1,
            },
          },
          validationRules: { required: true },
          format: "formatCurrency",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "numbersOrderFormsCustomersSentByPeriod",
          headerText: "Nb Commandes client validée",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 0, min: 0 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountOrderFormsCustomersSentByPeriod",
          headerText: "Mt Commandes validées",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          editType: "numericedit",
          defaultValue: 0,
          edit: {
            params: {
              decimals: 0,
              min: 0,
              format: "###### €",
              showSpinButton: false,
              step: 1,
            },
          },
          validationRules: { required: true },
          format: "formatCurrency",
          allowFiltering: true,
        },
        {
          type: "number",
          format: "formatNumber",
          field: "numbersInvoicesSentByPeriod",
          headerText: "Nb Factures émises",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 0, min: 0 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountInvoicesSentByPeriod",
          headerText: "Mt Factures envoyées",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          editType: "numericedit",
          defaultValue: 0,
          edit: {
            params: {
              decimals: 0,
              min: 0,
              format: "###### €",
              showSpinButton: false,
              step: 1,
            },
          },
          validationRules: { required: true },
          format: "formatCurrency",
          allowFiltering: true,
        },
      ];
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createCollaboratorGoal({
          collaboratorGoal: args.data,
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        await this.updateCollaboratorGoal({ collaboratorGoal: args.data });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    onRowClicked(event) {
      //this.$refs.overviewgrid.selectRow(event.rowIndex, false)
      // this.$refs.overviewgrid.$refs.overviewgrid.startEdit();
    },
    async archiveButtonClicked(args) {
      await this.archiveCollaboratorGoals({
        collaboratorGoalIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async restoreButtonClicked(args) {
      await this.restoreCollaboratorGoals({
        collaboratorGoalIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    async deleteButtonClicked(args) {
      await this.deleteCollaboratorGoals({
        collaboratorGoalIds: [args.rowData.id],
      });
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getCollaboratorGoalsArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getCollaboratorGoals({});
        this.archive = false;
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
